import api from "@/services/api"
import router from '@/router/index';
import { Message } from 'element-ui';
import i18n from '../assets/i18n/';
import home from '@/services/home';

const state = () => ({
    siginStatus: false,
    sigupStatus: false,
    coverStatus: false,
    cininfoStatus: false,
    cininfoStatusH5: false, // H5 影片详情
    token: null,
    mine: {},
    avatar: 'df',
    changeAvatar: window.localStorage.getItem('avatar') ,
    edEmaStatus: false,
    edPwdStatus: false,
    vipStatus: false,
    passwordStatus: false,
    SiteList: {},  // 站点列表
    recordlist: [], // 观影记录
    memberStatus: [],
    banklist: [],
    modifyStatus: false,
    bdemlStatus: false,
    feedbackList:  [],
});

const mutations = {
    SET_PASSWORD_STATUS(state, data) {
        state.passwordStatus = data;
    },
    SET_SIGIN_STATUS(state, data) {
        state.siginStatus = data;
    },
    SET_CININFO_STATUS(state, data) {
        state.cininfoStatus = data;
    },
    SET_CININFO_STATUS_H5(state, data) {
        state.cininfoStatusH5 = data;
    },
    SET_SIGUP_STATUS(state, data) {
        state.sigupStatus = data;
    },
    SET_COVER_STATUS(state, data) {
        state.coverStatus = data;
    },
    SET_TOKEN(state, data) {
        state.token = data;
    },
    SET_MINE(state, data){
        state.mine = data;
    },
    SET_MEMBERSTATUS(state, data){
        state.memberStatus = data;
    },
    SET_BANKLIST(state, data){
      state.banklist = data;
    },
    SET_AVATAR(state, data){
        window.localStorage.setItem('avatar', data);
        state.changeAvatar = data;
    },
    SET_EDAVT_STATUS(state, data) {
        state.edAvtStatus = data;
    },
    SET_EDEMA_STATUS(state, data) {
        state.edEmaStatus = data;
    },
    SET_EDPWD_STATUS(state, data) {
        state.edPwdStatus = data;
    },
    SET_VIP_STATUS(state, data) {
        state.vipStatus = data;
    },
    SET_SITE_LIST(state, data){
        state.SiteList = data;
    },
    SET_RECORDLIST(state, data) {
        state.recordlist = data;
    },
    SET_MODIFY_STATUS(state, data){
        state.modifyStatus = data;
    },
    SET_BDEML_STATUS(state, data) {
        state.bdemlStatus = data;
    },
    SET_FEEDBACK_STATUS(state, data) {
      state.feedbackList = data;
    },
};

const actions = {
    SetCoverStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
    },
    SetPasswordStatusFn({commit}, data){
        commit("SET_PASSWORD_STATUS", data);
    },
    SetSiginStatusFn({commit}, data){
        if(data){
            gtag("event", "ct_popup", {
                ct_popup_type:  "登录弹框"
            });
        };
        commit("SET_SIGIN_STATUS", data);
    },
    SetSigupStatusFn({commit}, data){
        if(data){
            gtag("event", "ct_popup", {
                ct_popup_type:  "注册弹窗"
            });
        };
        commit("SET_SIGUP_STATUS", data);
    },
    SetCininfoStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_CININFO_STATUS", data);
    },
    SetCininfoStatusH5Fn({commit}, data){
        commit("SET_CININFO_STATUS_H5", data);
    },
    SetEdavtStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_EDAVT_STATUS", data);
    },
    SetEdemaStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_EDEMA_STATUS", data);
    },
    SetEdpwdStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_EDPWD_STATUS", data);
    },
    SetModifyStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_MODIFY_STATUS", data);
    },
    SetVipStatusFn({commit}, data){
        commit("SET_COVER_STATUS", data);
        commit("SET_VIP_STATUS", data);
    },
    SetBdemlStatusFn({commit}, data){
        // commit("SET_COVER_STATUS", data);
        commit("SET_BDEML_STATUS", data);
    },
    AccountCheckRegemailFn({commit}, params){
        return api.accountCheckRegemail(params)
            .then(res => {
                const { status, message }  = res.data;
                if(status != 2) Message.error(message);
                return status;
            })
    },
    AccountSignUpFn({commit}, params){
        return api.accountSignUp(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const token = data.token;
                    commit("SET_TOKEN", token);
                    window.localStorage.setItem("token", token);
                    // commit("SET_VIP_STATUS", true);
                    Message.success(i18n.t('account.1'));
                }
                return code;
            })
    },
    BindEmailFn({commit}, params){
        return api.bindEmail(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const token = data.token;
                    commit("SET_TOKEN", token);
                    window.localStorage.setItem("token", token);
                    // commit("SET_VIP_STATUS", true);
                    Message.success(i18n.t('account.1'));
                }
                return code;
            })
    },
    AccountSignInFn({commit}, params){
        return api.accountSignIn(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    const token = data.token;
                    commit("SET_TOKEN", token);
                    window.localStorage.setItem("token", token);
                    const signRoute = window.localStorage.getItem('path');
                    if(signRoute) {
                        router.push(signRoute);
                        window.localStorage.removeItem('path');
                    }
                    // window.location.reload();
                    Message.success(i18n.t('account.2'));
                }
                return code;
            })
    },
    FetchUserInfoFn({commit}, params){
        return api.fetchUserInfo(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_MINE', data);
                }else{
                    window.localStorage.clear();
                    router.push('/');
                }
                return code;
            })
    },
    EditEmailFn({commit}, params){
        return api.editEmail(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    Message.success(i18n.t('account.3'));
                }
                return code;
            })
    },
    EditPasswordFn({commit}, params){
        return api.editPassword(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    Message.success(i18n.t('account.3'));
                }
                return code;
            })
    },
    EditEmailbackFn({commit}, params){
        return api.editEmailback(params)
            .then(res => {
                const { code, data } = res;
                return code;
            })
    },
    ResetAccountState({ commit }) {
        console.log(localStorage.getItem("token"))
        localStorage.getItem("token")
            ? commit('SET_TOKEN', localStorage.getItem("token"))
            : console.log("nothing to do");
    },
    SendEmailFn({commit}, params){
        return api.accountSendRegemail(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    Message.success(i18n.t('account.4'));
                }
                return code;
            })
    },
    // 用户观看列表
    getSendrecord({commit}, params){
        return api.movieWatchlist(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    commit('SET_RECORDLIST', data)
                }
                return code;
            })
    },
    // 退出
    SetSignOut({ commit }, params) {
        commit("SET_TOKEN", null);
        let showPopupintegral = window.localStorage.getItem('showPopupintegral');
        window.localStorage.clear();
        window.localStorage.setItem("showPopupintegral", showPopupintegral);
        Message.success(i18n.t('account.5'));
        router.push('/');
    },
    // 站点列表
    getSiteList({commit}, params){
        return api.getSiteList(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                    // let obj = JSON.parse(JSON.stringify(data))
                    commit('SET_SITE_LIST', data)
                    console.log('站点列表', data)
                }
                return code;
            })
    },
    //获取用户会员信息
    getMemberStatusFn({commit}, params){
        return api.getMemberStatus(params)
          .then(res => {
              const { code, data } = res;
              if(code == 200){
                  commit('SET_MEMBERSTATUS', data)
              }
              return res;
          })
    },
    //获取银行卡列表
    getBanklistFn({commit}, params){
      return api.getBanklist(params)
        .then(res => {
          const { code, data } = res;
          if(code == 200){
            commit('SET_BANKLIST', data)
          }
          return code;
        })
    },
    // 图片上传
    imgUpload({commit}, params){
        return api.imgUpload(params)
            .then(res => res)
    },
    // 修改用户信息
    SetAvatarFn({commit}, params){
        return api.sendUserInfo(params)
            .then(res => {
                const { code, data } = res;
                if(code == 200){
                     commit('SET_AVATAR', data.data.avatar);
                }
                return code;
            })
    },
    // 更换/取消订阅
    SendUnsubscribeFn({commit}, params){
      return api.unSubscribe(params)
        .then(res => res)
    },
    //获取银行卡列表
    getBillinfoFn({commit}, params){
      return api.getBillinfo(params)
        .then(res => res)
    },

    // 统计 观看数据上报
    getDataNikkatsuFn({commit}, params){
        return api.getDataNikkatsu(params)
            .then(res => {
                const { code, data } = res;
                return res;
            })
    },
    // USDT钱包地址/topPay/GCash
    FetchPayurlFn({commit}, params){
      return home.fetchPayurl(params)
        .then(res => {
          const { code, data} = res;
          return res;
        })
    },
    // 统计 来源信息
    PostlinkSpreadFn({commit}, params){
      return api.postlinkSpread(params)
        .then(res => {
          const { code, data } = res;
          return res;
        })
    },
    // 获取问题列表
   getFeedbackQAFn({commit}, params){
     return api.getFeedbackQA(params)
       .then(res => {
         const { code, data } = res;
         if(code == 200){
           commit('SET_FEEDBACK_STATUS', data)
         }
         return code;
       })
    },
    // 提交反馈数据
    submitFeedbackFn({commit}, params){
      return api.submitFeedback(params)
        .then(res => {
          const { code, data } = res;
          return res;
        })
    },
};

export default {
    state,
    mutations,
    actions,
}
