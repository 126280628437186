import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject){
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch(err => err);
}
Vue.use(VueRouter)

const routes = [
    { path: "/", redirect: "/landing" },
    {
        path: "/landing",
        name: "landing",
        component: () => import("../views/landing/landing.vue"),
    },
    {
        path: '*',
        redirect: '/'
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    let fromPath,toPath;
    if(from.path == '/'){
        fromPath = '/aoflix'
    }else{
        fromPath = from.path
    }
    if(to.path == '/'){
        toPath = '/aoflix'
    }else{
        toPath = to.path
    }
    if(fromPath !== '/'){
        gtag("event", "page_view", {
            page_title: toPath,   //url的路径
            page_location: window.location.protocol + '//' + window.location.hostname + toPath
        });
        _paq.push(['setReferrerUrl', window.location.protocol + '//' + window.location.hostname + from.path]);     //当前页面的上一个页面的URL
        _paq.push(['setCustomUrl', window.location.protocol + '//' + window.location.hostname + to.path]);        //window.location.protocol + window.location.hostname + to.path，当前页面URL
        _paq.push(['trackPageView']);
    }
    // 路由切换，滚动条在顶部
    document.getElementById("app").scrollTo(0,0);
    // 进入搜索页，保存当前路由
    if(to.path == '/search') {
        if(from.path !== '/search'){
            localStorage.setItem('preRoute', router.currentRoute.fullPath)
        }
    }
    if(toPath == '/aoflix') {
        if(fromPath !== '/path'){
            if (!window.localStorage.getItem("token")){
                    localStorage.setItem('path', router.history._startLocation)
            }
        }
    }
    next();
});

export default router
