<template>
     <div id="app" scroll-container>
        <transition-group name="fade" mode="out-in" v-show="isshow">
            <div v-show="Loadshow" :key="1">
                <section class="main" ref="main">
                    <router-view></router-view>
                </section>
            </div>
        </transition-group>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "application",
    data(){
        return {
            isshow: false,
            showPopupintegral:false,
        }
    },
    computed: {
        ...mapState({
            Loadshow: (state) => state.serial.Loadshow,
            loadLists: (state) => state.serial.loadLists,
            coverStatus: (state) => state.account.coverStatus,
            token: (state) => state.account.token,
            userMember: (state) => state.home.userMember
        }),
    },
    created(){
        setTimeout(()=>{
            this.isshow = true;
        },800)
    },
};


</script>
<style>
.fade-enter-active, .fade-leave-active {
    transition:All 1s ease-in-out;
}
.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: scale(1.05);
}
</style>
